import {SwipeableDrawer} from "@mui/material";
import React, {useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGear} from "@fortawesome/sharp-light-svg-icons";
import i18next from "i18next";
import CookieContentAnalytics from "./type/Analytics";
import CookieContentAds from "./type/Ads";
import CookieContentRequired from "./type/Required";

interface Props {
    className?: string
}

export default function CookieConsentDrawer({className = ''}: Props) {
    const [open, setOpen] = useState(false);

    const toggleDrawer = (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
            event &&
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' ||
                (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }

        setOpen(!open);
    };

    return (
        <>
            <button className={`btn btn-alt pt-1 pb-1 ps-4 pe-4 ${className}`}
                    onClick={toggleDrawer}>
                <div className={"d-flex flex-row align-items-center gap-2"}>
                    <span className={"fw-normal"}>{i18next.t('cookieConsent.settings').toString()}</span>
                </div>
            </button>
            <SwipeableDrawer
                anchor={'right'}
                open={open}
                onClose={toggleDrawer}
                onOpen={toggleDrawer}>
                <div className="position-relative mui-offcanvas">
                    <div className="ps-3 pe-3 pt-4 pb-3 bg-dark text-white" onClick={() => setOpen(false)}
                         role="button">
                        <i className="fa-solid fa-circle-xmark"></i>
                        <span className="ms-2 fw-bold">{i18next.t('general.close').toString()}</span>
                    </div>

                    <div className={"mt-4"}>
                        <CookieContentAnalytics/>
                        <CookieContentAds/>
                        <CookieContentRequired/>
                    </div>


                </div>
            </SwipeableDrawer>
        </>
    )
}
