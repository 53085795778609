import i18next from "i18next";
import React, {useEffect, useState} from "react";
import {roundPrice} from "../../../utils/roundPrice";
import apiClient from "../../../utils/apiClient";
import {useSelector} from "react-redux";
import {State} from "../../../stores/store";

interface Props {
    cart?: any,
    minShippingFree?: number,
    itemShippingFree?: boolean,
    shippingFree?: number,
    isLoading?: boolean,
    offCanvas?: boolean,
}

export default function CartShippingFreeProgressBar(
    {
        cart,
        minShippingFree = 60,
        shippingFree = 160,
        itemShippingFree,
        isLoading,
        offCanvas = false
    }: Props) {
    const [text, setText]: any = useState(false);
    const [bar, setBar] = useState(0);
    const [color, setColor] = useState('');
    const [promoShippingFree, setPromoShippingFree]: any = useState(false);
    const [promoTitle, setPromoTitle]: any = useState(false);
    const customer: any = useSelector((state: State) => state.customerState.customer);

    useEffect(() => {
        if (!cart.total) return;

        const fn = async () => {
            if (isShippingFree()) {
                return progressBarCompleted();
            }

            let res: boolean = false;
            if (cart.total.subTotal < 160) res = await shippingFreePromo();
            if (!res) {
                setPromoShippingFree(false);
                calcShippingFree();
            }
        }

        void fn();
    }, [cart.total.subTotal]);

    function calcShippingFree() {
        let percent = shippingFree === 0 ? 100 : (cart.total.subTotal / shippingFree) * 100;
        if (itemShippingFree) percent = 100;

        if (percent >= 100) return progressBarCompleted();

        setBar(percent);
        setColor('#73becf');
        setText(` (${i18next.t('general.missing').toString()} ${roundPrice(shippingFree - cart.total.subTotal)})`)
    }

    function calcPromoShippingFree(total: number, current: number) {
        let percent = current === total ? 100 : (current / total) * 100
        if (itemShippingFree) percent = 100;
        setBar(percent);
        setColor(percent >= 100 ? '#4d9781' : '#73becf');
    }

    const shippingFreePromo = async () => {
        let promo: any = cart.items.find((item: any) => item.promoShippingFree && item.promoProgressBar);
        if (!promo) return false;
        const result: any = await apiClient.post('/api/gift/list', {id: promo.detailId, progressBar: true});
        if (!result.data) return false;
        const p: any = result.data[0];
        if (!p || !p.gift) return false;
        setPromoShippingFree(p);
        calcPromoShippingFree(promoValue(p), promoCurrentValue(p));
        setPromoTitle(infoText(promo));
        return true;
    }

    const promoValue = (promo: any) => {
        if (promo.gift.quantity) return promo.gift.quantity;
        return promo.gift.amount;
    }

    const promoCurrentValue = (promo: any) => {
        try {
            return promo.rules[0].progress;
        } catch (e) {
            return 0;
        }
    }

    const isShippingFree = () => {
        try {
            return cart.total.shippingCost === 0;
        } catch (e) {
            return false;
        }
    }

    const progressBarCompleted = () => {
        setBar(100);
        setColor('#4d9781');
        setText('');
    }

    const infoText = (lineItem: any) => {
        try {
            if (lineItem.promoShippingFree) {
                if (lineItem.promoQuantity) return i18next.t('cartOffCanvas.shippingFreeInfoQuantity', {quantity: lineItem.promoQuantity});
                if (lineItem.promoAmount) return i18next.t('cartOffCanvas.shippingFreeInfoAmount', {amount: lineItem.promoAmount});
            }
            if (lineItem.promoQuantity) return i18next.t('cartOffCanvas.giftInfoQuantity', {quantity: lineItem.promoQuantity});
            if (lineItem.promoAmount) return i18next.t('cartOffCanvas.giftInfoAmount', {amount: lineItem.promoAmount});

            return i18next.t('cartOffcanvas.giftInfo');
        } catch (e) {
        }

    }

    if (promoShippingFree) {
        return (
            <>
                <hr className={"primary-color"}/>
                <div className={"ps-3 pe-3 mt-2 mb-4"}>
                    {promoTitle ? (
                        <p className={"fw-bold fs-7 mb-2"}>{promoTitle}</p>
                    ) : <></>}
                    {(promoShippingFree.gift.description && promoShippingFree.gift.description.length) ? (
                        <p className={"sup-1"}>{promoShippingFree.gift.description}</p>
                    ) : <></>}
                    <div className="progress position-relative">
                        <div className="progress-bar" role="progressbar"
                             style={{width: `${bar}%`, backgroundColor: color}}
                             aria-valuenow={25} aria-valuemin={0} aria-valuemax={100}>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    if (offCanvas && [14, 79, 80, 81, 82].includes(customer.shippingMethodId)) return <></>

    if (cart.total.subTotal < minShippingFree) return <></>

    return (
        <>
            <hr className={"primary-color"}/>
            <div className={"ps-3 pe-3 mt-2 mb-4"}>
                <p className={"sup-1"}>
                    {i18next.t('general.shippingFree').toString()} {text}
                    {bar >= 100 ?
                        <i className="fa-solid fa-circle-check text-green ms-1"></i> : <></>}
                </p>
                <div className="progress position-relative">
                    <div className="progress-bar" role="progressbar"
                         style={{width: `${bar}%`, backgroundColor: color}}
                         aria-valuenow={25} aria-valuemin={0} aria-valuemax={100}>
                    </div>
                </div>
            </div>
        </>
    )
}