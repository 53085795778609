import React from "react";
import {roundPrice} from "../../utils/roundPrice";
import RatingStar from "../rating/Star";
import Link from "../link/Link";
import {connect} from "react-redux";
import Image from "next/image";
import {stripHtml} from "string-strip-html";
import {setSelectedProduct} from "../../stores/product/productSelectorSlice";
import imageService from "../../services/product/imageService";
import productLinkService from "../../services/product/productLinkService";
import ProductBadge from "../product/Badge";
import ProductWishlist from "../product/Wishlist";
import ProductAddToCart from "../product/AddToCart";

import LinearProgress, {LinearProgressProps} from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import ProductBadgeGift from "../product/badge/Gift";
import ProductBadgeTopSeller from "../product/badge/TopSeller";
import ProductBadgeNew from "../product/badge/New";
import ProductBadgeDiscount from "../product/badge/Discount";
import i18next from "i18next";
import productTranslationService from "../../services/product/productTranslationService";
import {imageSizes} from "../../utils/imageSizes";

export class DailyDealTile extends React.PureComponent<any, any> {
    product: any;
    percentValue: any;

    constructor(props: any, context: any) {
        super(props, context);
        this.product = props.product;
        this.state = {
            stateProduct: this.product
        };

        if (this.product.max_amount === null || this.product.current_amount === null) {
            this.percentValue = false;
        } else {
            if (this.product.current_amount <= 0) {
                this.product.current_amount = 0;
            }
            this.percentValue = Math.floor(100 / this.product.max_amount * this.product.current_amount);
        }
    }

    image() {
        return (
            <Link href={productLinkService.getLink(this.state.stateProduct)}
                  className="d-block dark text-center">

                {this.state.stateProduct.show_percent ? (
                    <>
                        <div className={"badge-container mt-2 d-flex flex-row"} style={{minHeight: "40px"}}>
                            <div className={"product-badge bg-black me-2"}>
                                <span className={"text-white"}>{this.state.stateProduct.show_percent}%</span>
                            </div>
                            <ProductBadgeGift product={this.state.stateProduct}/>
                            <ProductBadgeNew product={this.state.stateProduct}/>
                            <ProductBadgeTopSeller product={this.state.stateProduct}/>
                        </div>
                    </>
                ) : (
                    <ProductBadge product={this.state.stateProduct}/>
                )}

                <div className="image-container d-flex align-items-end justify-content-center my-3 mx-auto">
                    <Image src={this.state.stateProduct.cover}
                           alt={this.state.stateProduct.name}
                           width={175}
                           height={175}
                           sizes={imageSizes({xs: '125px', md: '175px'})}
                           className="mw-100 mh-100 w-auto h-auto"/>
                </div>
            </Link>
    );
    }

    info() {
        const {listing, showDescription, showCart} = this.props;

        return (
            <>
                <Link href={productLinkService.getLink(this.state.stateProduct)}
                      className="d-block dark">
                    <div className="product-tile-text mb-1"><b>{this.state.stateProduct.brand}</b></div>
                    <div className="product-tile-text multi-line mb-1">{productTranslationService.getName(this.state.stateProduct)}</div>
                    {showDescription ? (
                        <div className={"mb-3"}>{stripHtml(productTranslationService.getDescription(this.state.stateProduct)).result.substring(0, 150)}...</div>
                    ) : (
                        <></>
                    )}

                    {this.state.stateProduct.price && this.product.current_amount !== 0 ? (
                        <div className="flex flex-row" style={{height: '25px'}}>
                            <span className="fw-bold">{roundPrice(this.state.stateProduct.price)}</span>
                            <span className="text-decoration-line-through ms-2 pseudo-price">
                                  {(this.state.stateProduct.price !== this.state.stateProduct.pseudoPrice) && (
                                      <span>{roundPrice(this.state.stateProduct.pseudoPrice)}</span>
                                  )}
                                </span>
                        </div>
                    ) : <div  style={{height: '25px'}}></div>}

                    {/*<RatingStar rating={this.state.stateProduct.reviews} total={this.state.stateProduct.reviewTotal} showTotal={reviewTotal}/>*/}
                </Link>

                {this.product.current_amount !== null && this.percentValue !== false ? (
                    <div className={"mt-2 progressbar-wrapper"} style={{height: '25px'}}>
                        <Box sx={{ width: '100%', mr: 1 }}>
                            <LinearProgress variant="determinate" value={this.percentValue ?? 0} />
                        </Box>
                        <Box sx={{ minWidth: 35 }}>
                            <p className={"sup-2 me-2 mt-1"}>
                                {i18next.t('progressbar.amount', {current: this.state.stateProduct.current_amount, max: this.state.stateProduct.max_amount}).toString()}
                            </p>
                        </Box>
                    </div>
                ) : <div className={"progressbar-wrapper"} style={{height: '25px'}}></div>}

                {this.product.orderNumber && showCart ? (
                    <div className={"actions mt-3 p-0"}>
                        <div className={"row g-1 w-100"}>
                            <div className={"col-4"}>
                                <ProductWishlist product={this.state.stateProduct}/>
                            </div>
                            <div className={"col-8"}>
                                {!this.state.stateProduct.lastStock ? (
                                    <ProductAddToCart product={this.state.stateProduct} listing={listing}/>
                                ) : (
                                    <>
                                        {this.state.stateProduct.lastStock && this.state.stateProduct.stock >= 0 && (
                                            <ProductAddToCart product={this.state.stateProduct} listing={listing}/>
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                ) : <></>}
            </>
        );
    }


    render() {
        const {className, style, children, showWide} = this.props;

        if (children) return children;

        return (
            <div className={'position-relative bg-white daily-deal-tile-h '+className} style={style}>
                {this.product.current_amount !== null && this.product.current_amount <= 0 ? (
                    <Link href={productLinkService.getLink(this.state.stateProduct)} className="d-block dark">
                        <div className={'overlay rounded-4 position-absolute w-100 h-100 top-0 start-0'}></div>
                    </Link>
                ) : <></>}

                {showWide ? (
                    <>
                        <div className={"col-md-5 mb-4 mb-md-0"}>
                            {this.image()}
                        </div>

                        <div className={"col-md-7"}>
                            {this.info()}
                        </div>
                    </>
                ) : (
                    <>
                        {this.image()}
                        {this.info()}
                    </>
                )}

            </div>
        )
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        setSelectedProduct: (value: any) => dispatch(setSelectedProduct(value))
    }
}

const mapStateToProps = (state: any) => { return {}}

export default connect(mapStateToProps, mapDispatchToProps)(DailyDealTile)

// connect(mapStateToProps, mapDispatchToProps)(Component)

// export default class ProductTile extends Component {}

// export default ProductTile
